<template>
  <div class="pageBody">
    <div class="body full-container">
      <Breadcrumb />
      <h1>{{ this.thePageTitle }}</h1>
      <hr />
      <el-row class="rowGap">
        <el-col :span="22">
          <el-input
            ref="filterInput"
            v-model="filterCondition.q"
            placeholder="Please enter username/name/phone/email/wechat/skype/line"
          />
        </el-col>
        <el-col :span="2" style="padding-left: 20px">
          <el-button type="primary" @click="setFilterText">
            {{ $t("button.search") }}
          </el-button>
        </el-col>
      </el-row>
      <el-row class="rowGap">
        <router-link class="buttonMargin" :to="{ name: 'UserList' }">
          <el-button :type="getFindUserBtnsStatus('UserList')">All</el-button>
        </router-link>
        <router-link class="buttonMargin" :to="{ name: 'TeacherList' }">
          <el-button :type="getFindUserBtnsStatus('TeacherList')"
            >Teachers</el-button
          >
        </router-link>
        <router-link class="buttonMargin" :to="{ name: 'StudentList' }">
          <el-button :type="getFindUserBtnsStatus('StudentList')"
            >Students</el-button
          >
        </router-link>
        <router-link class="buttonMargin" :to="{ name: 'AdminList' }">
          <el-button :type="getFindUserBtnsStatus('AdminList')"
            >Admin</el-button
          >
        </router-link>
      </el-row>
      <div v-if="$route.name === 'TeacherList'" class="rowGap">
        <h5></h5>
        <div class="rowGap">
          <b>Subjects: </b>
          <el-switch
            v-model="filterCondition.subjectMode"
            @change="handleSubjectsChange"
            active-color="#42a16a"
            inactive-color="#42a16a"
            :active-text="$t('users.needToIncludeAllSubjects')"
            :inactive-text="$t('users.onlyIncludeASubjects')"
            active-value="and"
            inactive-value="or"
          />
        </div>
        <div class="rowGap">
          <el-checkbox-group
            v-model="filterCondition.subjects"
            @change="handleSubjectsChange"
          >
            <el-checkbox
              :key="subject.value"
              v-for="subject in subjects"
              :label="subject.value"
            >
              {{ subject.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <el-collapse
        v-if="isRoleAdmin()"
        v-model="openFilterCollapse"
        @change="switchFilterCollapse"
      >
        <el-collapse-item name="acticeFilter">
          <template slot="title">
            <h4>{{ $t("users.filter") }}</h4>
            <span v-if="openFilterCollapse === 'none'">
              <span key="clickHere" class="clickHere">
                <i class="far fa-hand-point-left" />
                Click here : )
              </span>
            </span>
          </template>
          <div class="rowGap">
            <h3>
              Tags
              <router-link :to="{ name: 'tags', query: { tagType: 'user' } }">
                <el-button type="primary" size="mini">{{
                  $t("pageTitle.tags")
                }}</el-button>
              </router-link>
            </h3>
            <div class="rowGap">
              <el-switch
                v-model="filterCondition.tagMode"
                @change="handleTagsChange"
                active-color="#42a16a"
                inactive-color="#42a16a"
                :active-text="$t('users.needToIncludeAllTags')"
                :inactive-text="$t('users.onlyIncludeATags')"
                active-value="and"
                inactive-value="or"
              />
            </div>
            <div class="rowGap">
              <el-checkbox-group
                v-model="filterCondition.tags"
                @change="handleTagsChange"
              >
                <el-checkbox :key="tag.id" v-for="tag in tags" :label="tag.id">
                  {{ helper.displayI18nText($i18n.locale, tag.name) }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-row class="rowGap">
        <hr class="separate-line" />
        <h4>
          {{ $t("users.searchResult") }}
          <router-link :to="{ name: 'QuickRegister' }">
            <el-button type="primary" size="small">
              <i class="fa fa-plus"></i>
              創建賬號
            </el-button>
          </router-link>
        </h4>
        <hr />
      </el-row>
      <div v-loading="!users" element-loading-spinner="el-icon-loading">
        <el-row class="rowGap">
          A total of <span class="resultCount">{{ userCount }}</span> search
          users
        </el-row>
        <el-row class="rowGap">
          <el-radio-group
            v-model="filterCondition.isActive"
            @change="setIsActive"
            size="mini"
          >
            <el-radio-button :label="1">Active</el-radio-button>
            <el-radio-button :label="0">Inactive</el-radio-button>
          </el-radio-group>
        </el-row>
        <el-row class="rowGap">
          <router-view
            :users="users"
            @deleteUser="
              id => {
                deleteUser(id);
              }
            "
            @toggleShowBio="
              id => {
                toggleShowBio(id);
              }
            "
            @toggleShowRequest="
              (id, showOn) => {
                toggleShowRequest(id, showOn);
              }
            "
            @toggleUserActive="
              id => {
                toggleUserActive(id);
              }
            "
          />
        </el-row>
        <el-row class="rowGap" justify="center">
          <el-pagination
            background
            :page-size="50"
            layout="prev, pager, next"
            :total="userCount"
            :current-page="Number(filterCondition.page)"
            @current-change="setCurrentPage"
          />
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper, user } from "@ivy-way/material";
import usersApi from "@/apis/users";
import tagApi from "@/apis/tag";
import roleMixin from "@/mixins/role";
import Breadcrumb from "@/components/Breadcrumb";
import subjectsData from "@/data/subjects.json";

export default {
  metaInfo() {
    return {
      title: `${this.thePageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb
  },
  mixins: [roleMixin],
  async created() {
    if (this.$route.query.page) {
      this.filterCondition.page = this.$route.query.page;
    }
    this.filterCondition.isActive = Number(this.$route.query.activeType ?? 1);
    if (this.$route.query.q) {
      this.filterCondition.q = this.$route.query.q;
    }
    if (!this.isRoleAdmin() && !this.$route.query.page) {
      this.$router.push({ name: "StudentList" });
    }
    this.fetchUsers();
    this.fetchTags();
  },
  computed: {
    helper() {
      return helper;
    },
    userMethods() {
      return user;
    },
    subjects() {
      let subjects = [];

      subjectsData.forEach(({ options }) => {
        subjects = [...subjects, ...options];
      });
      subjects.sort((a, b) => (a.label > b.label ? 1 : -1));
      return subjects;
    },
    thePageTitle() {
      switch (this.$route.name) {
        case "UserList":
          return this.$t("pageTitle.UserList");
        case "StudentList":
          return this.$t("pageTitle.StudentList");
        case "TeacherList":
          return this.$t("pageTitle.TeacherList");
        case "AdminList":
          return this.$t("pageTitle.AdminList");
        default:
          return "";
      }
    }
  },
  data() {
    return {
      openFilterCollapse: "none",
      filterCondition: {
        page: 1,
        q: "",
        isActive: 1,
        role: this.getSearchTargetRole(),
        tags: [],
        tagMode: "or",
        subjects: [],
        subjectMode: "or"
      },
      userCount: 0,
      users: null,
      tags: []
    };
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") this.fetchUsers();
    });
  },
  methods: {
    switchFilterCollapse(collapseName) {
      if (collapseName.length === 0) {
        this.openFilterCollapse = "none";
      } else {
        this.openFilterCollapse = "acticeFilter";
      }
    },
    setCurrentPage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          q: this.filterCondition.q,
          page,
          activeType: this.filterCondition.isActive
        }
      });
    },
    getSearchTargetRole() {
      switch (this.$route.name) {
        case "UserList":
          return "";
        case "StudentList":
          return "student";
        case "TeacherList":
          return "teacher";
        case "AdminList":
          return "super-admin";
        default:
      }
    },
    async fetchTags() {
      let tagsResponse = null;
      switch (this.$route.name) {
        case "AdminList":
        case "UserList":
          tagsResponse = await tagApi.fetchTags("user");
          break;
        case "StudentList":
          tagsResponse = await tagApi.fetchTags("student");
          break;
        case "TeacherList":
          tagsResponse = await tagApi.fetchTags("teacher");
          break;
        default:
          return "";
      }
      this.tags = tagsResponse.tags;
    },
    async fetchUsers() {
      let result;
      if (this.isRoleAdmin()) {
        result = await usersApi.getUsers(this.filterCondition);
      } else if (this.isRoleTeacher()) {
        result = await usersApi.getStudentUsersByTeacher(this.filterCondition);
      } else if (this.isRoleParent()) {
        result = await usersApi.getChildUsersByParents(this.filterCondition);
      }
      const users = result.users;

      this.totalPage = users.last_page;
      this.userCount = users.total;
      this.users = null;
      switch (this.$route.name) {
        case "UserList":
          this.users = this.formatUsers(users);
          break;
        case "StudentList":
          this.users = this.formatStudents(users);
          break;
        case "TeacherList":
          this.users = this.formatTeachers(users);
          break;
        case "AdminList":
          this.users = this.formatAdmins(users);
          break;
        default:
      }
    },
    formatUsers(users) {
      return users.data.map(user => ({
        id: user.id,
        isActive: user.is_active,
        name: this.userMethods.displayName(user.first_name, user.last_name),
        chineseName: user.chinese_name,
        username: user.username,
        email: user.email,
        phone: user.phone,
        lineId: user.line_id,
        wechatId: user.line_id
      }));
    },
    formatStudents(users) {
      return users.data.map((user, index) => ({
        index: index + 1,
        id: user.id,
        isActive: user.is_active,
        name: this.userMethods.displayName(user.first_name, user.last_name),
        username: user.username,
        email: user.email,
        phone: user.phone,
        line: user.line_id,
        wechatId: user.wechat_id
      }));
    },
    formatTeachers(users) {
      const getCreateDateFromLessonLog = lessonLog =>
        lessonLog ? moment(lessonLog.created_at).format("YYYY-MM-DD") : "";
      const getPropFromLessonLog = (lessonLog, prop) =>
        lessonLog ? lessonLog[prop] : "";
      return users.data.map(user => ({
        id: user.id,
        isActive: user.is_active,
        name: this.userMethods.displayName(user.first_name, user.last_name),
        photo_url: user.photo_url,
        lineId: user.line_id,
        wechatId: user.line_id,
        phone: user.phone,
        school: user.teacher_profile.college.en,
        hoursAvailable: user.teacher_profile.hours_available,
        subjects: user.teacher_profile.subjects,
        showBill: user.teacher_profile.show_bio,
        show_on_invitation_list: user.teacher_profile.show_on_invitation_list,
        currentYear: user.teacher_profile.current_year,
        college_graduation_year: user.teacher_profile.college_graduation_year,
        notes: user.teacher_profile.notes,
        studentsCount: user.teacher_profile.students_count,
        lastLessonDate: getCreateDateFromLessonLog(
          user.teacher_profile.latest_teaching_log
        ),
        lastTeachingId: getPropFromLessonLog(
          user.teacher_profile.latest_lesson_log?.teaching_log,
          "id"
        ),
        lastLessonClassId: getPropFromLessonLog(
          user.teacher_profile.latest_lesson_log?.teaching_log,
          "class_id"
        ),
        lastLessonSessionId:
          user.teacher_profile.latest_lesson_log?.teaching_log.session_class
            .course_session_id || ""
      }));
    },
    formatAdmins(users) {
      return users.data.map(user => ({
        id: user.id,
        isActive: user.is_active,
        name: this.userMethods.displayName(user.first_name, user.last_name),
        chineseName: user.chinese_name,
        username: user.username,
        email: user.email,
        homePhone: user.home_phone,
        phone: user.phone,
        lineId: user.line_id,
        wechatId: user.line_id
      }));
    },
    async toggleUserActive(id) {
      try {
        await usersApi.toggleUserActive(id);
        await this.fetchUsers();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async toggleShowBio(id) {
      try {
        await usersApi.toggleTeacherShoeBio(id);
        await this.fetchUsers();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async toggleShowRequest(id, showOn) {
      let show = 1;
      if(showOn === 1){
        show = 0;
      }
      console.log(showOn);
      await usersApi.toggleTeacherShoeRequest(id, {
        show_on_invitation_list: show
      });
      await this.fetchUsers();
      this.$message.success(this.$t("message.update_success"));
    },
    setIsActive(isActive) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          page: 1,
          activeType: isActive
        }
      });
    },
    setFilterText() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...(this.$route.query || {}),
          page: 1,
          q: this.filterCondition.q
        }
      });
    },
    async handleSubjectsChange() {
      await this.fetchUsers();
    },
    async handleTagsChange() {
      await this.fetchUsers();
    },
    async deleteUser(id) {
      this.$confirm("請問是否要永久刪除此使用者", "Warning", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning"
      }).then(async () => {
        await usersApi.destoryUser(id);
        await this.fetchUsers();
      });
    },
    getFindUserBtnsStatus(buttonName) {
      if (this.$route.name === buttonName) {
        return "primary";
      }
      return "default";
    }
  }
};
</script>

<style scoped>
.rowGap {
  margin: 20px 0px;
}

::v-deep .el-collapse-item__header {
  margin-top: 0.4rem;
}

.resultCount {
  font-size: 24px;
  color: #3c763d;
}

.buttonMargin {
  margin-right: 8px;
}

.pageBody {
  padding: 0px 40px;
}

.clickHere {
  color: #777;
  margin-left: 16px;
  animation: 0.8s rotate;
  position: relative;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    left: -8px;
  }

  50% {
    left: 0px;
  }

  100% {
    left: -8px;
  }
}
</style>
